import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_74_3883)">
        <path
          d="M23.3973 23.9156L21.7517 21.5057V21.5055L21.7256 21.4672L13.9275 10.0504L13.9275 10.0503L13.0459 8.75913L13.0459 8.7591L7.46773 0.59222L7.46772 0.592205L7.00558 -0.0845875L6.96091 -0.15H6.8817H0.0273438H-0.256716L-0.0965317 0.0845865L1.57518 2.53278L1.57519 2.53279L8.99201 13.3929L8.99204 13.3929L9.87361 14.6827L9.87364 14.6828L15.8328 23.4086L15.8329 23.4086L16.2952 24.0849L16.3399 24.1502H16.4191H23.2734H23.5575L23.3973 23.9156ZM17.3127 22.2873L11.1663 13.2877L11.1663 13.2877L10.2847 11.9972L10.2847 11.9972L3.26068 1.7125H5.9877L11.753 10.1542L11.753 10.1542L12.6346 11.4446L12.6346 11.4446L20.0399 22.2873H17.3127Z"
          fill="black"
          stroke="black"
          strokeWidth="0.3"
        />
        <path
          d="M10.2842 11.9935L10.1735 11.8314L10.0462 11.9808L9.00103 13.2073L9.00101 13.2073L-0.114148 23.8992L-0.324992 24.1465H0H1.97753H2.04674L2.09166 24.0938L10.1109 14.6918L10.1109 14.6918L11.1561 13.4659L11.2305 13.3786L11.1658 13.2839L10.2842 11.9935Z"
          fill="black"
          stroke="black"
          strokeWidth="0.3"
        />
        <path
          d="M22.5603 0.0973414L22.7713 -0.15H22.4462H20.4687H20.3997L20.3548 -0.0976152L18.6619 1.87752L18.6616 1.87783L12.806 8.74638L12.806 8.74639L11.7609 9.97226L11.6865 10.0595L11.7511 10.1542L12.6327 11.4446L12.7434 11.6066L12.8707 11.4574L13.9159 10.2323L13.9159 10.2323L22.5603 0.0973414Z"
          fill="black"
          stroke="black"
          strokeWidth="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_3883">
          <rect width="23.2727" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
