import React from 'react'
import styled from 'styled-components'
import { Link } from 'widgets'
import { Login, Config } from './types'
import { connectorLocalStorageKey } from '../../../packages/uikit/src/widgets/WalletModal'

const WalletStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  width: 100%;
  padding: 32px 16px;
  cursor: pointer;
  height: 100%;

  border-radius: 12px;
  border: 1px solid #363636;
  background: rgba(13, 13, 13, 0.24);
  transition: 0.3s ease;

  :hover {
    transform: scale(1.03);
  }
  .modal-title {
    font-family: 'Roboto';
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
    width: max-content;
  }

  .modal-image {
    padding: 12px;
    background: #222;
    border-radius: 4px;
  }

  svg {
    width: 30px;
    height: auto;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 48px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 32px;
      height: 30px;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    .modal-title {
      font-size: 14px;
      line-height: 100%;
    }
  }
`

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig

  let linkAction: any = {
    onClick: () => {
      login(walletConfig.connectorId)
      window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
      onDismiss()
    },
  }

  if (typeof window !== 'undefined' && !window.ethereum && walletConfig.href) {
    linkAction = {
      style: {
        textDecoration: 'none',
      },
      as: Link,
      href: walletConfig.href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return (
    <WalletStyled id={`wallet-connect-${title.toLocaleLowerCase()}`} {...linkAction}>
      <div className="modal-image">
        <Icon width="40px" />
      </div>
      <div className="modal-title">{title}</div>
    </WalletStyled>
  )
}

export default WalletCard
