/* eslint-disable no-new */
/* eslint-disable dot-notation */
import { ResetCSS } from '@pancakeswap/uikit'
import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import FixedSubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useUserAgent from 'hooks/useUserAgent'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import '../style/animation.css'
import '../style/global.css'
import PublicLayout from 'layouts/PublicLayout'
import ModalConnect from 'components/ModalConnect'
import ModalNFTDetail from 'components/ModalMyNft/ModalNFTDetail'
import ModalSuccess from 'components/ModalMyNft/ModalSuccess'
import { useWeb3React } from '@web3-react/core'
import ModalLoadingV2 from 'components/ModalLoading/ModalLoadingV2'
import { Blocklist, Updaters } from '..'
import Providers from '../Providers'
import ErrorBoundary from '../components/ErrorBoundary'
import GlobalStyle from '../style/Global'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  // useEagerConnect()
  useUserAgent()
  // useAccountEventListener()
  // useSentryUser()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="https://dapp.bunnychz.com/favicon.ico?v=1.1" />
        <link rel="shortcut icon" type="image/png" href="https://dapp.bunnychz.com/favicon.ico?v=1.1" />

        {/* <!-- Og --> */}
        <meta property="og:title" content="Bunny" />
        <meta
          property="og:description"
          content="Welcome To Bunny - The Bunny Ecosystem Is Extremely Strong And Is Providing The Leading BNB Mining Solution On The Blockchain Daily Rewards Of 8% And ROI Of 2,920%."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Bunny" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Bunny" />
        <meta property="og:image" content="https://dapp.bunnychz.com/new-graph-mining.png" />
        <meta property="og:url" content="https://dapp.bunnychz.com" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="Bunny" />
        <meta name="twitter:description" content="Bunny!" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Bunny" />
        <meta name="twitter:image" content="https://dapp.bunnychz.com/new-graph-mining.png" />
        <meta name="twitter:creator" content="@Bunny" />

        {/* <!-- Title --> */}
        <title>Bunny - Welcome to Bunny</title>
        <meta name="keywords" content="Bunny" />
        <meta
          name="description"
          content="Welcome To Bunny - The Bunny Ecosystem Is Extremely Strong And Is Providing The Leading BNB Mining Solution On The Blockchain Daily Rewards Of 8% And ROI Of 2,920%."
        />
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      {/* <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `,
        }}
      /> */}
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
  authLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps, ...props }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Fragment
  const { account } = useWeb3React()

  return (
    <ProductionErrorBoundary>
      {/* <TokenUpdater /> */}
      <Layout>
        {Component.fullLayout ? (
          <Component {...pageProps} />
        ) : (
          <PublicLayout isAuth={Component?.authLayout} isLogged={account}>
            <div id="google_translate_element" />
            <Component {...pageProps} />
          </PublicLayout>
        )}
      </Layout>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      {/* <ModalBuyNow /> */}
      <ModalConnect />
      <ModalNFTDetail />
      <ModalSuccess />
      <ModalLoadingV2 />
    </ProductionErrorBoundary>
  )
}

export default MyApp
