import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="34" height="16" viewBox="0 0 34 16" fill="none" {...props}>
      <path
        d="M16.432 1.34818C16.3865 1.25706 16.3045 1.20239 16.2043 1.20239H11.8783L11.8418 1.22973L11.7781 1.27529L5.22982 9.91326V1.44841C5.22982 1.38463 5.21161 1.32085 5.16607 1.27529L5.05679 1.20239H0.91289C0.849138 1.20239 0.785384 1.22973 0.739847 1.27529L0.666992 1.38463V14.9156C0.666992 14.9794 0.694309 15.0432 0.739847 15.0888L0.849138 15.1616H5.63055L5.66698 15.1343L5.73073 15.0979L16.3956 1.60331C16.4593 1.53042 16.4684 1.43019 16.4229 1.33907L16.432 1.34818Z"
        fill="url(#paint0_linear_357_13347)"
      />
      <path
        d="M1.63281 14.9154V1.45733C1.63281 1.45733 1.64192 1.41177 1.66013 1.39355C1.67835 1.37532 1.69656 1.36621 1.72389 1.36621H4.98436C4.98436 1.36621 5.02989 1.37532 5.04811 1.39355C5.06632 1.41177 5.07543 1.42999 5.07543 1.45733V11.1978C5.07543 11.1978 5.10275 11.2707 5.13918 11.2798C5.17561 11.2889 5.21204 11.2798 5.23936 11.2525L12.6984 1.40266C12.6984 1.40266 12.7166 1.38443 12.7257 1.37532C12.7348 1.36621 12.753 1.36621 12.7621 1.36621H16.2047C16.2047 1.36621 16.2685 1.38443 16.2867 1.41177C16.2958 1.4391 16.2958 1.47555 16.2776 1.50289L5.63098 14.961C5.63098 14.961 5.61277 14.9792 5.60366 14.9883C5.59456 14.9883 5.57634 14.9883 5.56723 14.9883H1.733C1.733 14.9883 1.68746 14.9792 1.66924 14.961C1.65103 14.9428 1.64192 14.9245 1.64192 14.8972L1.63281 14.9154Z"
        fill="url(#paint1_linear_357_13347)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        opacity="0.8"
        d="M1.79688 14.8424V1.5301H4.91162V11.1977C4.91162 11.307 4.97538 11.3982 5.08467 11.4346H5.11199L5.16663 11.4437C5.2486 11.4437 5.31235 11.4073 5.367 11.3435L12.826 1.49365L16.05 1.52099L5.52182 14.8333H1.79688V14.8424Z"
        fill="url(#paint2_linear_357_13347)"
      />
      <path
        d="M13.6636 15.3712C13.5816 15.3712 13.5179 15.3348 13.4633 15.2619C13.3904 15.1708 13.4359 15.0341 13.4359 15.0341C13.4359 15.0341 14.2647 12.8381 14.292 12.7652C14.3194 12.6924 14.3467 12.6559 14.3922 12.6195C14.4378 12.583 14.4833 12.5739 14.5379 12.5739H22.0152C22.17 12.5739 22.3248 12.5557 22.4705 12.5101C22.6162 12.4646 22.7529 12.4008 22.8804 12.3097C23.0079 12.2185 23.1172 12.1183 23.2173 11.9907C23.3084 11.8723 23.3904 11.7356 23.4541 11.5807L24.1645 9.78569H19.3558C18.6454 9.78569 18.0443 9.7128 17.5798 9.5579C17.0971 9.403 16.7419 9.15698 16.5325 8.83807C16.323 8.51916 16.2319 8.0909 16.2775 7.57153C16.3139 7.07038 16.4687 6.47812 16.7419 5.79473L16.9787 5.19335C17.252 4.50086 17.5707 3.89948 17.935 3.38922C18.2993 2.87896 18.7183 2.45982 19.1736 2.15002C19.629 1.84022 20.1572 1.5942 20.7583 1.4393C21.3503 1.2844 22.0152 1.20239 22.7346 1.20239H30.5215C30.6035 1.20239 30.6854 1.24795 30.731 1.31173C30.7765 1.37552 30.7856 1.46663 30.7583 1.53953L29.8385 3.88126L29.7656 3.94504C29.7656 3.94504 29.6745 3.9906 29.6199 3.9906H23.2902C22.8713 3.9906 22.5161 4.09083 22.2337 4.29129C21.9514 4.49175 21.7237 4.79244 21.5689 5.19335L21.4141 5.59427C21.3321 5.80384 21.2866 5.98608 21.2866 6.14098C21.2866 6.27766 21.3139 6.39611 21.3776 6.49634C21.4414 6.58746 21.5325 6.66035 21.66 6.70591C21.7966 6.76058 21.9787 6.78792 22.1973 6.78792H25.9587C26.6235 6.78792 27.1791 6.86081 27.6162 7.0066C28.0716 7.1615 28.4086 7.38929 28.609 7.69909C28.8093 8.0089 28.9004 8.41893 28.864 8.91096C28.8275 9.38478 28.6909 9.9406 28.4359 10.5784L28.0443 11.5716C27.7893 12.2185 27.4887 12.7835 27.1427 13.2573C26.7966 13.7402 26.3958 14.1411 25.9496 14.4418C25.5124 14.7425 25.0024 14.9794 24.4377 15.1343C23.8731 15.2892 23.2538 15.3621 22.5798 15.3621H13.6909L13.6636 15.3712Z"
        fill="url(#paint3_linear_357_13347)"
      />
      <path
        d="M22.552 15.207H14.4737C14.4737 15.207 14.419 15.1888 14.4008 15.1706C14.3826 15.1523 14.3826 15.1159 14.3917 15.0886L15.266 12.7924C15.266 12.7924 15.2843 12.7651 15.3025 12.7559C15.3207 12.7468 15.3298 12.7377 15.348 12.7377H22.8252C22.9983 12.7377 23.1713 12.7104 23.3352 12.6648C23.4992 12.6193 23.6449 12.5373 23.7906 12.437C23.9363 12.3368 24.0547 12.2183 24.164 12.0908C24.2642 11.9541 24.3553 11.8083 24.419 11.6352L25.1658 9.73994C25.1658 9.73994 25.1658 9.68527 25.1658 9.65794C25.1476 9.63971 25.1203 9.62149 25.093 9.62149H20.175C19.4737 9.62149 18.8999 9.54859 18.4537 9.40281C18.0074 9.25702 17.6886 9.03833 17.4974 8.74676C17.3061 8.45518 17.2332 8.06337 17.2697 7.58045C17.3061 7.09752 17.4518 6.52348 17.7159 5.84921L17.8343 5.54852L17.9527 5.24783C18.2169 4.56445 18.5356 3.98129 18.8908 3.48014C19.246 2.98811 19.6467 2.57808 20.0839 2.27739C20.521 1.9767 21.0311 1.73979 21.6139 1.59401C22.1968 1.43911 22.8434 1.36621 23.5538 1.36621H30.5301C30.5301 1.36621 30.5848 1.38443 30.603 1.40266C30.6212 1.42088 30.6212 1.45733 30.603 1.48466L29.7014 3.78083C29.7014 3.78083 29.6831 3.80817 29.6649 3.81728C29.6467 3.82639 29.6376 3.8355 29.6194 3.8355H23.2897C22.8343 3.8355 22.4518 3.94485 22.1422 4.16353C21.8325 4.38221 21.5866 4.70112 21.4136 5.13849L21.3316 5.33895L21.2496 5.53941C21.1586 5.7672 21.113 5.96766 21.113 6.1499C21.113 6.32302 21.1495 6.46881 21.2314 6.59638C21.3134 6.71483 21.4318 6.80595 21.5957 6.86973C21.7505 6.93351 21.9509 6.96085 22.1877 6.96085H25.9491C26.5957 6.96085 27.1331 7.03374 27.552 7.17042C27.9709 7.30709 28.2806 7.52578 28.4627 7.79913C28.6449 8.0816 28.7178 8.45518 28.6904 8.90166C28.6631 9.34814 28.5174 9.89484 28.2806 10.5144L27.889 11.5076C27.6431 12.1363 27.3425 12.6922 27.0056 13.1569C26.6686 13.6216 26.277 14.0043 25.858 14.3049C25.43 14.5965 24.9473 14.8243 24.3917 14.9701C23.8453 15.1159 23.2351 15.1888 22.5702 15.1888L22.552 15.207Z"
        fill="url(#paint4_linear_357_13347)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        opacity="0.8"
        d="M14.5836 15.0429L15.3942 12.9016H22.8168C23.008 12.9016 23.1902 12.8743 23.3723 12.8196C23.5454 12.7649 23.7184 12.6829 23.8732 12.5736C24.0281 12.4642 24.1647 12.3367 24.2831 12.1909C24.3924 12.0451 24.4926 11.8811 24.5654 11.6989L25.3122 9.80361C25.3396 9.73071 25.3304 9.6396 25.2849 9.57581C25.2394 9.51203 25.1665 9.46647 25.0754 9.46647H20.1574C19.4743 9.46647 18.9188 9.39358 18.4816 9.2569C18.0718 9.12022 17.7804 8.92888 17.6073 8.66464C17.4343 8.40951 17.3705 8.04503 17.3978 7.59856C17.4343 7.13386 17.58 6.56892 17.835 5.91288L18.0718 5.3115C18.3359 4.64634 18.6456 4.06318 18.9917 3.58026C19.3377 3.09734 19.7294 2.70553 20.1483 2.41395C20.5672 2.12237 21.0682 1.89458 21.6237 1.74879C22.1884 1.603 22.8259 1.521 23.5181 1.521H30.3851L29.5381 3.66227H23.2539C22.7712 3.66227 22.3523 3.78072 22.0062 4.01763C21.6692 4.25453 21.4051 4.60989 21.223 5.06548L21.0682 5.4664C20.968 5.72153 20.9224 5.9311 20.9133 6.13156C20.9133 6.34113 20.9589 6.52337 21.0682 6.66915C21.1683 6.81494 21.3141 6.9334 21.5053 6.99718C21.6784 7.06096 21.8969 7.09741 22.1519 7.09741H25.9133C26.5417 7.09741 27.07 7.16119 27.4707 7.29787C27.8532 7.42543 28.1355 7.61678 28.2995 7.8628C28.4634 8.10882 28.5272 8.45506 28.4998 8.86509C28.4725 9.30246 28.3359 9.83095 28.0991 10.4323L27.7075 11.4255C27.4616 12.0451 27.1701 12.5827 26.8423 13.0383C26.5144 13.4939 26.141 13.8675 25.7312 14.1499C25.3213 14.4324 24.8477 14.6511 24.3195 14.7878C23.7822 14.9335 23.1902 15.0064 22.5436 15.0064H14.5654L14.5836 15.0429Z"
        fill="url(#paint5_linear_357_13347)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M25.6669 2.86992C21.441 2.86992 18.0166 2.36877 18.0166 1.74917C18.0166 1.12957 21.441 0.628418 25.6669 0.628418C29.8927 0.628418 33.3171 1.12957 33.3171 1.74917C33.3171 2.36877 29.8927 2.86992 25.6669 2.86992Z"
        fill="url(#paint6_radial_357_13347)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M23.4628 8.41899C26.7473 8.41899 29.41 7.91721 29.41 7.29824C29.41 6.67927 26.7473 6.17749 23.4628 6.17749C20.1783 6.17749 17.5156 6.67927 17.5156 7.29824C17.5156 7.91721 20.1783 8.41899 23.4628 8.41899Z"
        fill="url(#paint7_radial_357_13347)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M18.8817 14.1868C15.8307 14.1868 13.3535 13.7221 13.3535 13.1389C13.3535 12.5558 15.8307 12.0911 18.8817 12.0911C21.9327 12.0911 24.41 12.5558 24.41 13.1389C24.41 13.7221 21.9327 14.1868 18.8817 14.1868Z"
        fill="url(#paint8_radial_357_13347)"
      />
      <mask
        id="mask0_357_13347"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="16"
        height="14"
      >
        <path
          d="M1.63281 14.9157V1.45757C1.63281 1.45757 1.64192 1.41201 1.66013 1.39379C1.67835 1.37557 1.69656 1.36646 1.72389 1.36646H4.98436C4.98436 1.36646 5.02989 1.37557 5.04811 1.39379C5.06632 1.41201 5.07543 1.43024 5.07543 1.45757V11.1981C5.07543 11.1981 5.10275 11.271 5.13918 11.2801C5.17561 11.2892 5.21204 11.2801 5.23936 11.2527L12.6984 1.4029C12.6984 1.4029 12.7166 1.38468 12.7257 1.37557C12.7348 1.36646 12.753 1.36646 12.7621 1.36646H16.2047C16.2047 1.36646 16.2685 1.38468 16.2867 1.41201C16.2958 1.43935 16.2958 1.4758 16.2776 1.50313L5.63098 14.9612C5.63098 14.9612 5.61277 14.9795 5.60366 14.9886C5.59456 14.9886 5.57634 14.9886 5.56723 14.9886H1.733C1.733 14.9886 1.68746 14.9795 1.66924 14.9612C1.65103 14.943 1.64192 14.9248 1.64192 14.8975L1.63281 14.9157Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_357_13347)">
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M11.2599 6.38753C6.59688 12.0824 2.24351 16.2374 1.53313 15.6542C0.822752 15.0711 4.02858 9.98669 8.69159 4.28271C13.3546 -1.41216 17.708 -5.56713 18.4184 -4.98398C19.1287 -4.40082 15.9229 0.683556 11.2599 6.38753Z"
          fill="url(#paint9_radial_357_13347)"
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M9.24674 4.49206C9.0828 17.5219 6.96987 28.0551 4.52907 28.0278C2.08828 28.0005 0.248567 17.4126 0.412501 4.38272C0.576436 -8.64713 2.68937 -19.1804 5.13016 -19.153C7.57096 -19.1257 9.41067 -8.53779 9.24674 4.49206Z"
          fill="url(#paint10_radial_357_13347)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_357_13347"
          x1="8.56315"
          y1="15.1708"
          x2="8.56315"
          y2="1.21151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#161415" />
          <stop offset="1" stopColor="#333132" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_357_13347"
          x1="-0.744233"
          y1="12.5737"
          x2="13.3517"
          y2="-1.51552"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#C06D00" />
          <stop offset="0.16" stopColor="#FFD657" />
          <stop offset="0.26" stopColor="#FFCF48" />
          <stop offset="0.42" stopColor="#E49E20" />
          <stop offset="0.58" stopColor="#FEF7E9" />
          <stop offset="0.67" stopColor="white" />
          <stop offset="0.8" stopColor="#FFC73A" />
          <stop offset="0.87" stopColor="#FFD351" />
          <stop offset="1" stopColor="#C06D00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_357_13347"
          x1="-0.598386"
          y1="12.446"
          x2="13.1877"
          y2="-1.33356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#404040" />
          <stop offset="0.05" stopColor="#6A6A6A" />
          <stop offset="0.06" stopColor="#6F6F6F" />
          <stop offset="0.07" stopColor="#7F7F7F" />
          <stop offset="0.09" stopColor="#999999" />
          <stop offset="0.1" stopColor="#BDBDBD" />
          <stop offset="0.11" stopColor="#D0D0D0" />
          <stop offset="0.13" stopColor="#D0D0D0" />
          <stop offset="0.15" stopColor="#BFBFBF" />
          <stop offset="0.19" stopColor="#9A9A9A" />
          <stop offset="0.23" stopColor="#7F7F7F" />
          <stop offset="0.26" stopColor="#6F6F6F" />
          <stop offset="0.29" stopColor="#606060" />
          <stop offset="0.31" stopColor="#4B4B4B" />
          <stop offset="0.32" stopColor="#3C3C3C" />
          <stop offset="0.34" stopColor="#333333" />
          <stop offset="0.37" stopColor="#313131" />
          <stop offset="0.44" stopColor="#727272" />
          <stop offset="0.46" stopColor="#777777" />
          <stop offset="0.5" stopColor="#888888" />
          <stop offset="0.54" stopColor="#A3A3A3" />
          <stop offset="0.59" stopColor="#C8C8C8" />
          <stop offset="0.63" stopColor="#F3F3F3" />
          <stop offset="0.71" stopColor="#F7F7F7" />
          <stop offset="0.77" stopColor="white" />
          <stop offset="0.85" stopColor="white" />
          <stop offset="0.86" stopColor="#E7E7E7" />
          <stop offset="0.89" stopColor="#ACACAC" />
          <stop offset="0.9" stopColor="#9A9A9A" />
          <stop offset="0.91" stopColor="#9D9D9D" />
          <stop offset="0.92" stopColor="#A8A8A8" />
          <stop offset="0.93" stopColor="#BABABA" />
          <stop offset="0.94" stopColor="#D3D3D3" />
          <stop offset="0.95" stopColor="#F8F8F8" />
          <stop offset="0.96" stopColor="#EAEAEA" />
          <stop offset="0.97" stopColor="#C7C7C7" />
          <stop offset="0.99" stopColor="#8E8E8E" />
          <stop offset="1" stopColor="#686868" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_357_13347"
          x1="22.0789"
          y1="15.3712"
          x2="22.0789"
          y2="1.2115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#161415" />
          <stop offset="1" stopColor="#333132" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_357_13347"
          x1="15.2387"
          y1="16.0088"
          x2="30.2094"
          y2="1.0453"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04" stopColor="#C06D00" />
          <stop offset="0.16" stopColor="#FFD657" />
          <stop offset="0.26" stopColor="#FFCF48" />
          <stop offset="0.42" stopColor="#E49E20" />
          <stop offset="0.58" stopColor="#FEF7E9" />
          <stop offset="0.67" stopColor="white" />
          <stop offset="0.8" stopColor="#FFC73A" />
          <stop offset="0.87" stopColor="#FFD351" />
          <stop offset="1" stopColor="#C06D00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_357_13347"
          x1="15.3942"
          y1="15.8538"
          x2="30.0551"
          y2="1.19994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#404040" />
          <stop offset="0.05" stopColor="#6A6A6A" />
          <stop offset="0.06" stopColor="#6F6F6F" />
          <stop offset="0.07" stopColor="#7F7F7F" />
          <stop offset="0.09" stopColor="#999999" />
          <stop offset="0.1" stopColor="#BDBDBD" />
          <stop offset="0.11" stopColor="#D0D0D0" />
          <stop offset="0.13" stopColor="#D0D0D0" />
          <stop offset="0.15" stopColor="#BFBFBF" />
          <stop offset="0.19" stopColor="#9A9A9A" />
          <stop offset="0.23" stopColor="#7F7F7F" />
          <stop offset="0.26" stopColor="#6F6F6F" />
          <stop offset="0.29" stopColor="#606060" />
          <stop offset="0.31" stopColor="#4B4B4B" />
          <stop offset="0.32" stopColor="#3C3C3C" />
          <stop offset="0.34" stopColor="#333333" />
          <stop offset="0.37" stopColor="#313131" />
          <stop offset="0.44" stopColor="#727272" />
          <stop offset="0.46" stopColor="#777777" />
          <stop offset="0.5" stopColor="#888888" />
          <stop offset="0.54" stopColor="#A3A3A3" />
          <stop offset="0.59" stopColor="#C8C8C8" />
          <stop offset="0.63" stopColor="#F3F3F3" />
          <stop offset="0.71" stopColor="#F7F7F7" />
          <stop offset="0.77" stopColor="white" />
          <stop offset="0.85" stopColor="white" />
          <stop offset="0.86" stopColor="#E7E7E7" />
          <stop offset="0.89" stopColor="#ACACAC" />
          <stop offset="0.9" stopColor="#9A9A9A" />
          <stop offset="0.91" stopColor="#9D9D9D" />
          <stop offset="0.92" stopColor="#A8A8A8" />
          <stop offset="0.93" stopColor="#BABABA" />
          <stop offset="0.94" stopColor="#D3D3D3" />
          <stop offset="0.95" stopColor="#F8F8F8" />
          <stop offset="0.96" stopColor="#EAEAEA" />
          <stop offset="0.97" stopColor="#C7C7C7" />
          <stop offset="0.99" stopColor="#8E8E8E" />
          <stop offset="1" stopColor="#686868" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_357_13347"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.5405 2.3756) rotate(90) scale(1.13168 7.64152)"
        >
          <stop stopColor="white" />
          <stop offset="0.09" stopColor="#CBCBCB" />
          <stop offset="0.19" stopColor="#959595" />
          <stop offset="0.3" stopColor="#686868" />
          <stop offset="0.4" stopColor="#424242" />
          <stop offset="0.5" stopColor="#252525" />
          <stop offset="0.6" stopColor="#101010" />
          <stop offset="0.7" stopColor="#040404" />
          <stop offset="0.8" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_357_13347"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.3346 7.96431) rotate(90) scale(1.13168 5.93223)"
        >
          <stop stopColor="white" />
          <stop offset="0.09" stopColor="#CBCBCB" />
          <stop offset="0.19" stopColor="#959595" />
          <stop offset="0.3" stopColor="#686868" />
          <stop offset="0.4" stopColor="#424242" />
          <stop offset="0.5" stopColor="#252525" />
          <stop offset="0.6" stopColor="#101010" />
          <stop offset="0.7" stopColor="#040404" />
          <stop offset="0.8" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_357_13347"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.9655 14.2637) rotate(90) scale(1.05624 5.53005)"
        >
          <stop stopColor="white" />
          <stop offset="0.09" stopColor="#CBCBCB" />
          <stop offset="0.19" stopColor="#959595" />
          <stop offset="0.3" stopColor="#686868" />
          <stop offset="0.4" stopColor="#424242" />
          <stop offset="0.5" stopColor="#252525" />
          <stop offset="0.6" stopColor="#101010" />
          <stop offset="0.7" stopColor="#040404" />
          <stop offset="0.8" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_357_13347"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.98342 5.31761) rotate(39.3234) scale(1.65933 13.3262)"
        >
          <stop stopColor="white" />
          <stop offset="0.09" stopColor="#CBCBCB" />
          <stop offset="0.19" stopColor="#959595" />
          <stop offset="0.3" stopColor="#686868" />
          <stop offset="0.4" stopColor="#424242" />
          <stop offset="0.5" stopColor="#252525" />
          <stop offset="0.6" stopColor="#101010" />
          <stop offset="0.7" stopColor="#040404" />
          <stop offset="0.8" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_357_13347"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.28899 4.30809) rotate(0.720342) scale(4.42404 23.5893)"
        >
          <stop stopColor="white" />
          <stop offset="0.09" stopColor="#CBCBCB" />
          <stop offset="0.19" stopColor="#959595" />
          <stop offset="0.3" stopColor="#686868" />
          <stop offset="0.4" stopColor="#424242" />
          <stop offset="0.5" stopColor="#252525" />
          <stop offset="0.6" stopColor="#101010" />
          <stop offset="0.7" stopColor="#040404" />
          <stop offset="0.8" />
        </radialGradient>
      </defs>
    </Svg>
  )
}

export default Icon
