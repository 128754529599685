/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Flex, Link } from 'widgets'
import { Col, Row } from 'antd'
import { TelegramIcon, TwitterIcon } from '../../../packages/uikit/src/components/Svg'

const FOOTER_LINK = [
  {
    title: 'About',
    href: '#',
  },
  {
    title: 'Tokenomics',
    href: '#',
  },
  {
    title: 'Roadmap',
    href: '#',
  },
  {
    title: 'How To Buy',
    href: '#',
  },
  {
    title: 'FAQ',
    href: '#',
  },
  {
    title: 'Contact',
    href: '#',
  },
]

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 42px 16px 0 16px;
  border-top: 1px solid rgba(250, 255, 255, 0.1);

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 60px 16px 0 16px;
  }

  .container {
    width: 100%;
    max-width: 1230px;
    margin-left: auto;
    margin-right: auto;
  }

  .description-footer {
    color: #fff;
    font-size: 17px;
    font-style: normal;
    line-height: 150%;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 19px;
    }

    span {
      display: contents;
      font-weight: 700;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    ${({ theme }) => theme.mediaQueries.lg} {
      row-gap: 26px;
    }

    li {
      a {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 18px;
        }
      }
    }
  }

  .title-social {
    color: var(--Base-White, #fff);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Clash Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 16px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 24px;
    }
  }

  .description-social {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 18px;

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-bottom: 32px;
    }

    span {
      display: contents;
      font-weight: 700;
    }
  }

  .btn-social {
    display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 10px 24px;
      font-size: 20px;
    }

    svg {
      width: 20px;
      margin-right: 8px;

      ${({ theme }) => theme.mediaQueries.lg} {
        width: 24px;
        margin-right: 12px;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;

      ${({ theme }) => theme.mediaQueries.lg} {
        margin-right: 16px;
      }
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-top: 1px solid #2d2d2d;
    margin-top: 30px;

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-top: 50px;
      height: 75px;
      font-size: 20px;
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()

  return (
    <FooterWrapper>
      <div className="container">
        <Row gutter={[32, 42]}>
          <Col span={24} lg={{ span: 8 }}>
            <div>
              <Link href="/" className="flex items-center gap-3 mb-4">
                <img src="/images/new-logo.png" alt="" className="w-full max-w-[66px] aspect-square" />
                <span className="text-[32px] uppercase font-bold text-white">Bunny</span>
              </Link>

              <p className="description-footer">
                {t('I am')} <span className="font-bold">Bunny</span>,{' '}
                {t('I will be the mascot representing luck and peace to Ukraine')}
              </p>
            </div>
          </Col>

          <Col span={24} lg={{ span: 4, offset: 1 }} xl={{ offset: 2 }}>
            <ul>
              <li>
                <Link href="/">{t('Fan Token')}</Link>
              </li>
              <li>
                <Link href="/">{t('The App')}</Link>
              </li>
              <li>
                <Link href="/">{t('Features')}</Link>
              </li>
              <li>
                <Link href="/">{t('Rewards')}</Link>
              </li>
              <li>
                <Link href="https://bunnychz.gitbook.io/whitepaper" external>
                  {t('Whitepaper')}
                </Link>
              </li>
            </ul>
          </Col>

          <Col span={24} lg={{ span: 8, offset: 1 }} xl={{ offset: 2 }}>
            <p className="title-social">{t('Join our community')}</p>
            <p className="description-social">
              {t('Join')} <span>Bunny</span> {t('in supporting Ukraine team and calling for peace!')}
            </p>
            <Flex>
              <Link href="https://twitter.com/BunnyChiliz" external className="btn-social">
                <TwitterIcon />
                Twitter
              </Link>
              <Link href="https://t.me/Bunny_Chiliz" external className="btn-social">
                <TelegramIcon />
                Telegram
              </Link>
            </Flex>
          </Col>
        </Row>

        <p className="copyright">© Bunny 2024, {t('All Rights Reserved')}</p>
      </div>
    </FooterWrapper>
  )
}

export default Footer
