/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core'
import { Checkbox, Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import InputCopy from 'components/CoppyItem/InputCopy'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import { setModalConnect } from 'state/modal/actions'
import BaseButton from 'widgets/BaseButton'
import { AppState } from '../../state/index'
import WalletCard from './WalletCard'
import config from './config'

const ModalContent = styled.div`
  .title {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #f1f1f1;
    margin-bottom: 24px;

    a {
      color: #3772ff;
    }
  }
`

const WrapperAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .btn-logout {
    width: fit-content;
    margin: 0 auto;
  }
`

const ModalConnect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { login, logout } = useAuth()
  const { account } = useWeb3React()
  const { toggle } = useSelector((state: AppState) => state.modal.modalConnect)

  const [isChecked, setIsChecked] = useState(false)

  const handleCancel = () => {
    setIsChecked(false)
    dispatch(setModalConnect({ toggle: false }))
  }
  const handleLogout = () => {
    setIsChecked(false)
    logout()
    handleCancel()
  }

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={410}
      footer={null}
      style={{ background: 'black', borderRadius: '12px', border: '1px solid white' }}
      closeIcon={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            marginTop: '8px',
          }}
        >
          <img src="/images/icons/close.png" alt="" />
        </div>
      }
      onCancel={handleCancel}
    >
      {account ? (
        <ModalContent>
          <WrapperAccount>
            <div className="title">{t('Your wallet')}</div>

            <InputCopy title={t('Your address')} value={account} />

            <BaseButton className="btn-logout" onClick={handleLogout}>
              {t('Logout')}
            </BaseButton>
          </WrapperAccount>
        </ModalContent>
      ) : (
        <Row>
          <Col span={24}>
            <div className="items-center h-full">
              <ModalContent>
                <div className="flex">
                  <div className="title">{t('Connect Wallet')}</div>
                </div>
                <div className="relative">
                  <Row gutter={[16, 16]} className="my-6">
                    {config.map((entry) => (
                      <Col key={entry.title} span={12}>
                        <WalletCard key={entry.title} walletConfig={entry} login={login} onDismiss={handleCancel} />
                      </Col>
                    ))}
                  </Row>

                  {!isChecked && (
                    <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-black opacity-50 cursor-not-allowed" />
                  )}
                </div>

                <div className="flex items-center space-x-3">
                  <Checkbox value={isChecked} checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
                  <p className="text-sm">
                    {t('Read and accept')} <span className="text-[#00BFF3]">{t('Terms of Use')}</span> {t('and')}{' '}
                    <span className="text-[#00BFF3]">{t('Privacy Policy')}</span>.
                  </p>
                </div>
              </ModalContent>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  )
}

export default React.memo(ModalConnect)
