import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

const useGetLocation = () => {
  const [data, setData] = useState<any>(null)

  const fetchLocation = useCallback(async () => {
    try {
      await axios.get('https://geolocation-db.com/json/').then((res) => {
        if (res.status === 200) {
          setData(res.data)
        }
        return data
      })
    } catch (error) {
      console.log(error)
      return data
    }

    return data
  }, [])

  useEffect(() => {
    fetchLocation()
  }, [fetchLocation])

  return data
}

export default useGetLocation
