import { MenuEntry } from './types'

/**
 * label: string
 * icon: svg
 * href: https || /path || 'function'
 * calloutClass: 'rainbow' || className => background rainbow and custom css
 * initialOpenState: true || false
 * items: array sample parent
 * att: attach => text || icon.png
 */

export const links: MenuEntry[] = [
  {
    label: 'Airdrop',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/shop.svg',
    href: 'https://dapp.ukadoge.com/airdrop',
  },
  {
    label: 'SHOP',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/mynft.svg',
    href: 'https://dapp.ukadoge.com/shop',
  },
  {
    label: 'Marketplace',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/shop.svg',
    href: 'https://dapp.ukadoge.com/marketplace',
  },
  {
    label: 'Auction',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/market.svg',
    href: 'https://dapp.ukadoge.com/auction',
  },
  {
    label: 'FARM',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/mynft.svg',
    href: 'https://dapp.ukadoge.com/farming',
  },
  {
    label: 'STAKING',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/mynft.svg',
    href: 'https://dapp.ukadoge.com/staking',
  },
  {
    label: 'Boss',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/auction.svg',
    href: 'https://dapp.ukadoge.com/boss',
  },
  {
    label: 'Contest',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/farm.svg',
    href: 'https://dapp.ukadoge.com/contest',
  },
]

export const menuItems = [
  {
    label: 'Home',
    icon: '',
    href: 'https://bunnychz.com/#home',
    actives: ['/home-blank'],
  },
  {
    label: 'Ino',
    icon: '',
    href: '/ino',
    actives: ['/ino'],
  },
  {
    label: 'My NFT',
    icon: '',
    href: '/my-nft',
    actives: ['/my-nft'],
  },

  {
    label: 'Game Prediction',
    icon: '',
    href: '/game-prediction',
    actives: ['/game-prediction'],
  },
  {
    label: 'Mining',
    icon: '',
    href: '/#mining',
    actives: ['/mining'],
  },
  {
    label: 'Whitepaper',
    icon: '',
    href: 'https://bunnychz.gitbook.io/whitepaper',
    actives: ['/whitepaper'],
  },
  // {
  //   label: 'Profit Sharing',
  //   icon: '',
  //   href: '/profit-share',
  //   actives: ['/profit-share'],
  // },
  // {
  //   label: 'Config',
  //   icon: '',
  //   href: '/config',
  //   actives: ['/config'],
  //   items: [
  //     {
  //       label: 'Box Config',
  //       icon: '',
  //       href: '/config',
  //     },
  //     {
  //       label: 'Sharing Config',
  //       icon: '',
  //       href: '/config',
  //     },
  //   ],
  // },
]

export default links
