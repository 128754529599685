import { useEffect, useState } from 'react'
import { getUserAffiliate } from 'state/affiliate/api'
import { UserAffiliateResponse } from 'state/affiliate/types'

export const useGetUserAffiliate = (address: string) => {
  const [data, setData] = useState<UserAffiliateResponse | undefined>()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [refresh, setRefresh] = useState(0)
  const [isFetched, setIsFetched] = useState(false)

  const handleRefresh = () => {
    setRefresh((prev) => prev + 1)
  }

  useEffect(() => {
    if (!address) return


    getUserAffiliate(address)
      .then((res) => {
        setData(res?.data)
      })
      .catch((err) => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
        setIsFetched(true)
      })
  }, [address, refresh])

  return { data, isLoading, isError, refresh: handleRefresh, isFetched }
}
