import { useWeb3React } from '@web3-react/core'
import { Layout } from 'antd'
import Footer from 'components/Footer'
import Menu from 'components/Menu'
import AuthHeader from 'components/Menu/AuthHeader'
import { LOCAL_STORAGE_KEY, ROOT_REF_ADDRESS } from 'config/constants'
import { useGetUserAffiliate } from 'hooks/useGetUserAffiliate'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { setUserAffiliate } from 'state/affiliate/api'
import { isAddress } from 'utils'
import { Box } from 'widgets'

const { Content } = Layout

const PublicLayout = ({ children, isAuth, isLogged }) => {
  const router = useRouter()

  if (
    isLogged ||
    [
      '/',
      '/ino',
      '/auction',
      '/auction/detail/[id]',
      '/game-prediction',
      '/game-history',
      '/game-prediction/[id]',
      '/mining',
    ].includes(router?.pathname) ||
    isAuth
  ) {
    const { account } = useWeb3React()
    const { data: userAffiliateData, isFetched, refresh } = useGetUserAffiliate(account)

    // handle set referral code for user
    useEffect(() => {
      let referralCode = ROOT_REF_ADDRESS
      const refStorage = localStorage.getItem(LOCAL_STORAGE_KEY.REFERRAL_CODE)

      if (refStorage) {
        referralCode = refStorage
      }

      // Validate ref address
      if (!referralCode) return
      if (!isAddress(referralCode)) return
      if (!account) return
      if (account?.toLocaleLowerCase() === referralCode?.toLocaleLowerCase()) return
      if (!isFetched) return
      if (userAffiliateData?.data?.tree?.refAddress) return

      setUserAffiliate(account, referralCode).then(() => {
        localStorage.removeItem(LOCAL_STORAGE_KEY.REFERRAL_CODE)
        refresh()
      })
    }, [account, isFetched, userAffiliateData?.data?.tree?.refAddress])

    return (
      <Layout
        style={{
          minHeight: '100vh',
          overflow: 'hidden',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div id="google_translate_element" />
        {isAuth ? <AuthHeader /> : <Menu />}
        <Content>
          <Box minHeight={isAuth ? '' : '100vh'} position="relative">
            {children}
          </Box>
        </Content>
        {!router?.pathname?.includes('profit-share') && <Footer />}
      </Layout>
    )
  }

  router.replace('/')
  return <></>
}

export default PublicLayout
