import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none" {...props}>
      <path
        d="M28.4056 2.18436L24.1481 22.5513C23.8266 23.9885 22.9892 24.3463 21.7991 23.6696L15.3116 18.8203L12.1816 21.8746C11.835 22.2263 11.5458 22.5196 10.8777 22.5196L11.3442 15.8182L23.3671 4.79808C23.8901 4.32583 23.2532 4.06313 22.5549 4.5364L7.69134 14.0304L1.29246 11.9983C-0.0991717 11.5578 -0.124364 10.5867 1.58268 9.90898L26.6109 0.127734C27.7697 -0.312826 28.7835 0.389412 28.4056 2.18538V2.18436Z"
        fill="black"
      />
    </Svg>
  );
};

export default Icon;
