import sample from 'lodash/sample'
import Cookies from 'js-cookie'

const NODE_1 = process.env.NEXT_PUBLIC_NODE_1
const NODE_1_CHZ = process.env.NEXT_PUBLIC_NODE_1_CHZ
const NODE_2 = process.env.NEXT_PUBLIC_NODE_2
const NODE_2_CHZ = process.env.NEXT_PUBLIC_NODE_2_CHZ
const NODE_3 = process.env.NEXT_PUBLIC_NODE_3
const NODE_3_CHZ = process.env.NEXT_PUBLIC_NODE_3_CHZ
const NODE_PRODUCTION = process.env.NEXT_PUBLIC_NODE_PRODUCTION
const NODE_PRODUCTION_CHZ = process.env.NEXT_PUBLIC_NODE_PRODUCTION_CHZ

if (process.env.NODE_ENV !== 'production' && (!NODE_1 || !NODE_2 || !NODE_3)) {
  throw Error('One base RPC URL is undefined')
}

// Array of available nodes to connect to
export const nodes = [NODE_1, NODE_2, NODE_3]
export const nodesCHZ = [NODE_1_CHZ, NODE_2_CHZ, NODE_3_CHZ]

const getNodeUrl = () => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the NEXT_PUBLIC_NODE_PRODUCTION from env file
  if (NODE_PRODUCTION) {
    return Cookies.get('chainId') === '56' ? NODE_PRODUCTION : NODE_PRODUCTION_CHZ
  }
  return sample(Cookies.get('chainId') === '56' ? nodes : nodesCHZ)
}

export default getNodeUrl
