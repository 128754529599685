import { useState, useCallback, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useInoContract, useInoContractCHZ } from './useContract'

const useCheckWhiteList = (account: string): any => {
  const contractMethod = useInoContract()
  const contractMethodCHZ = useInoContractCHZ()
  const [data, setData] = useState(false)

  const getData = useCallback(async () => {
    const contractMethodUse = Cookies.get('chainId') === '56' ? contractMethod : contractMethodCHZ

    if (contractMethodUse && account) {
      try {
        const result = await contractMethodUse.adminList(account)
        setData(result)
      } catch (error) {
        console.error('useCheckWhiteList', error)
      }
    }
  }, [account, contractMethod, contractMethodCHZ])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useCheckWhiteList
