import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 11.9999C21 12.1988 20.921 12.3896 20.7803 12.5303C20.6397 12.6709 20.4489 12.7499 20.25 12.7499H5.56031L11.0306 18.2193C11.1003 18.289 11.1556 18.3717 11.1933 18.4628C11.231 18.5538 11.2504 18.6514 11.2504 18.7499C11.2504 18.8485 11.231 18.9461 11.1933 19.0371C11.1556 19.1281 11.1003 19.2109 11.0306 19.2806C10.9609 19.3502 10.8782 19.4055 10.7872 19.4432C10.6961 19.4809 10.5985 19.5003 10.5 19.5003C10.4014 19.5003 10.3039 19.4809 10.2128 19.4432C10.1218 19.4055 10.0391 19.3502 9.96937 19.2806L3.21937 12.5306C3.14964 12.4609 3.09432 12.3782 3.05658 12.2871C3.01883 12.1961 2.9994 12.0985 2.9994 11.9999C2.9994 11.9014 3.01883 11.8038 3.05658 11.7127C3.09432 11.6217 3.14964 11.539 3.21937 11.4693L9.96937 4.7193C10.1101 4.57857 10.301 4.49951 10.5 4.49951C10.699 4.49951 10.8899 4.57857 11.0306 4.7193C11.1714 4.86003 11.2504 5.05091 11.2504 5.24993C11.2504 5.44895 11.1714 5.63982 11.0306 5.78055L5.56031 11.2499H20.25C20.4489 11.2499 20.6397 11.3289 20.7803 11.4696C20.921 11.6103 21 11.801 21 11.9999Z"
        fill="#000"
      />
    </Svg>
  )
}

export default Icon
