import { useWeb3React } from '@web3-react/core'
import { Button, Col, Input, Modal, Row } from 'antd'
import { PAYX_INO_NFT_CONTRACT } from 'config/constants/endpoints'
import { convertKeysToCamelCase } from 'helpers/CommonHelper'
import setListNFTs from 'hooks/setListNFTs'
import { useERC721 } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import debounce from 'lodash/debounce'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalLoading, setModalMyNft, setModalNftDetail } from 'state/modal/actions'
import { setMyNft } from 'state/nft/actions'
import styled from 'styled-components'
import { getTransactionReceiptMined, isAddress } from 'utils'
import { CloseIcon, TitleIcon } from 'widgets'
import { useRouter } from 'next/router'
import { AppState } from '../../state/index'

const ModalContent = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 12px;
  }

  .container-custom {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    filter: url('#goo');
    animation: rotate-move 2s ease-in-out infinite;
  }

  .dot {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .dot-3 {
    background-color: #00e6ff;
    animation: dot-3-move 2s ease infinite, index 6s ease infinite;
  }

  .dot-2 {
    background-color: #efaa00;
    animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
  }

  .dot-1 {
    background-color: #fdfdfd;
    animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
  }

  @keyframes dot-3-move {
    20% {
      transform: scale(1);
    }
    45% {
      transform: translateY(-18px) scale(0.45);
    }
    60% {
      transform: translateY(-90px) scale(0.45);
    }
    80% {
      transform: translateY(-90px) scale(0.45);
    }
    100% {
      transform: translateY(0px) scale(1);
    }
  }

  @keyframes dot-2-move {
    20% {
      transform: scale(1);
    }
    45% {
      transform: translate(-16px, 12px) scale(0.45);
    }
    60% {
      transform: translate(-80px, 60px) scale(0.45);
    }
    80% {
      transform: translate(-80px, 60px) scale(0.45);
    }
    100% {
      transform: translateY(0px) scale(1);
    }
  }

  @keyframes dot-1-move {
    20% {
      transform: scale(1);
    }
    45% {
      transform: translate(16px, 12px) scale(0.45);
    }
    60% {
      transform: translate(80px, 60px) scale(0.45);
    }
    80% {
      transform: translate(80px, 60px) scale(0.45);
    }
    100% {
      transform: translateY(0px) scale(1);
    }
  }

  @keyframes rotate-move {
    55% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    80% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes index {
    0%,
    100% {
      z-index: 3;
    }
    33.3% {
      z-index: 2;
    }
    66.6% {
      z-index: 1;
    }
  }
`

const NftContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  .item-left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  p {
    font-family: 'Roboto';
  }
`

const ConfirmButton = styled(Button)`
  height: auto;
  padding: 0;
  width: 100%;
  max-width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  color: #fff !important;
  min-height: 50px;
  border: 1px solid #ffea04;
  border-radius: 34778.336px;
  background: transparent;
  font-size: 16px;

  :hover,
  :focus {
    opacity: 1 !important;
    background: #ffea04;
    color: #000 !important;
  }

  :disabled {
    background: #666;
    border: none;
  }
`

const TransferButton = styled.div`
  background-color: transparent;
  height: auto;
  padding: 0 30px;
  border: none;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  min-height: 50px;
  border: 1px solid #ffea04;
  border-radius: 34778.336px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: 50px;
  }
`

const FormTransfer = styled.div`
  display: grid;
  grid-gap: 12px;

  input {
    padding: 12px;
    font-family: 'Roboto';
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    border-radius: 8px;
    border: 1px solid #ffea04;
    background: #1a1d21;
    box-shadow: 0px 0px 0px 4px rgba(132, 220, 245, 0.24);

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 10px 16px;
      font-size: 16px;
    }
  }
`

const ModalNFTDetail = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const { toggle, dataModal } = useSelector((state: AppState) => state.modal.modalNftDetail)
  const myNftList = useSelector((state: AppState) => state.nft.myNftList)

  // Hook
  const contractERC721 = useERC721(PAYX_INO_NFT_CONTRACT)
  const { toastError, toastSuccess } = useToast()

  // State
  const [isTransfer, setIsTransfer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transferAddress, setTransferAddress] = useState('')
  const [errorMassage, setErrorMessage] = useState('')

  const handleCancel = () => {
    if (!loading) {
      setErrorMessage('')
      setTransferAddress('')
      setIsTransfer(false)
      dispatch(setModalNftDetail({ toggle: false, dataModal: null }))
    }
  }

  const handleButtonTransfer = () => {
    if (dataModal?.type === 'DETAIL') {
      setIsTransfer((prev) => !prev)
      return
    }

    dispatch(setModalNftDetail({ toggle: false, dataModal: null }))
    router.push('/my-nft')
  }

  const handleChangeInput = debounce((val: string) => {
    setTransferAddress(val)
    setErrorMessage('')
  }, 500)

  const getBoxDataAfterTransfer = async (interval: any) => {
    if (account) {
      setListNFTs({ limit: 100, owner: account }, (res) => {
        if (res?.data?.length !== myNftList?.length) {
          clearInterval(interval)
          dispatch(setMyNft(convertKeysToCamelCase(res?.data)))
          setLoading(false)
          handleCancel()
          toastSuccess('Transfer successfully')
        }
      })
    }
  }

  const handleConfirmTransfer = async () => {
    if (!loading && contractERC721 && account && dataModal?.nft) {
      if (!isAddress(transferAddress)) {
        setErrorMessage('Wrong Wallet Address')
        return
      }

      if (isAddress(transferAddress) && transferAddress?.toLocaleLowerCase() === account?.toLocaleLowerCase()) {
        setErrorMessage('Do not use the address you are currently logged in')
        return
      }

      setLoading(true)

      try {
        await contractERC721
          .transferFrom(account, transferAddress, dataModal?.nft?.id)
          .then(async (res) => {
            let interval: any
            const receipt: any = await getTransactionReceiptMined(res?.hash, 500)

            if (receipt?.status) {
              interval = setInterval(() => getBoxDataAfterTransfer(interval), 5000)
            }
          })
          .catch((e) => {
            setLoading(false)
            handleCancel()
            toastError('Transfer failed')
          })
      } catch (error) {
        setLoading(false)
        handleCancel()
        toastError('Transfer failed')
      }
    }
  }

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={666}
      footer={null}
      closeIcon={
        <div className="flex items-center justify-center w-full h-full">
          <CloseIcon />
        </div>
      }
      onCancel={handleCancel}
    >
      <ModalContent>
        <NftContent>
          <p className="text-[26px] leading-1.5 font-bold text-white">
            {dataModal?.type === 'DETAIL' ? 'NFT Information' : 'Congratulations'}
          </p>

          <Row gutter={[20, 20]}>
            <Col span={24} lg={{ span: 12 }}>
              <div className="item-left">
                <img
                  src={dataModal?.nft?.animationUrl || dataModal?.nft?.image}
                  alt="avata"
                  className="md:w-[300px] w-[200px]"
                />
              </div>
            </Col>

            <Col span={24} lg={{ span: 12 }}>
              <div className="flex flex-col">
                <div className="flex md:gap-[32px] gap-4 mb-4">
                  <h2 className="font-bold md:text-[24px] text-[20px] text-white">{dataModal?.nft?.name}</h2>
                </div>

                <div className="flex flex-col gap-4 mb-4 px-4 py-3 bg-[#121715] rounded-[12px]">
                  <div className="flex items-center justify-between">
                    <p className="text-white text-sm">NFT ID</p>
                    <p className="text-right text-white font-[900]">#{dataModal?.nft?.id}</p>
                  </div>

                  <div className="flex items-center justify-between">
                    <p className="text-white text-sm">Rarity</p>
                    <p className="text-right text-white font-[900]">
                      {dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Rare')?.value ||
                        dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Rare')?.value}
                    </p>
                  </div>

                  <div className="flex items-center justify-between">
                    <p className="text-white text-sm">Collection</p>
                    <p className="text-right text-white font-[900]">
                      {dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Description')?.value ||
                        dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Description')?.value}
                    </p>
                  </div>

                  <div className="flex items-center justify-between">
                    <p className="text-white text-sm">Borntime</p>
                    <p className="text-right text-white font-[900]">
                      {dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Birthday')?.valueDate ||
                      dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Birthday')?.value_date
                        ? moment(
                            dataModal?.nft?.attributes?.find((x) => x?.traitType === 'Birthday')?.valueDate ||
                              dataModal?.nft?.attributes?.find((x) => x?.trait_type === 'Birthday')?.value_date,
                          ).format('HH:mm [UTC] MMMM D')
                        : '--'}
                    </p>
                  </div>
                </div>

                {!isTransfer && (
                  <TransferButton onClick={handleButtonTransfer}>
                    {dataModal?.type === 'DETAIL' ? 'Transfer NFT' : 'Go to my NFT'}
                  </TransferButton>
                )}
              </div>
            </Col>
          </Row>
        </NftContent>

        {loading && (
          <div className="container-custom">
            <div className="dot dot-1" />
            <div className="dot dot-2" />
            <div className="dot dot-3" />
          </div>
        )}

        {dataModal?.type === 'DETAIL' && isTransfer && (
          <FormTransfer className="mt-[15px]">
            <p className="text-md font-bold leading-[1] text-white md:text-left text-center">Send to</p>
            <Input
              placeholder="Please enter the wallet address you want to transfer"
              onChange={(e) => handleChangeInput(e?.target?.value)}
            />
            {errorMassage && <p className="text-[12px] text-[#ff4d4e] text-center">{errorMassage}</p>}
            <ConfirmButton onClick={handleConfirmTransfer} disabled={loading}>
              Confirm
            </ConfirmButton>
          </FormTransfer>
        )}
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalNFTDetail)
