import styled from 'styled-components'
import { UserListIcon } from '../../../packages/uikit/src/components/Svg'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  img {
    width: 100%;
    max-width: 48px;
    opacity: 0;
  }

  &.active {
    span {
      &:nth-child(1) {
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &:nth-child(2) {
        &::before {
          content: unset;
        }
      }

      &:nth-child(3) {
        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  span {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 3px;
      background-image: url('/images/header/icon-menu-child.png');
      background-size: 100% 100%;
      transition: 0.1s;
    }

    &:nth-child(1) {
      &::before {
        transform: translate(-50%, -300%);
      }
    }

    &:nth-child(3) {
      &::before {
        transform: translate(-50%, 230%);
      }
    }
  }
`

const CollapseIcon = ({ active, toggleMenu }) => {
  return (
    <Container className={active ? 'active' : ''} onClick={toggleMenu}>
      {/* <span />
      <span />
      <span /> */}
      {/* <img src='/images/header/icon-menu.png' alt='' /> */}
      <UserListIcon className="mobile-menu-icon" />
    </Container>
  )
}

export default CollapseIcon
