import { Box } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { Dropdown } from 'antd'
import LocalizeSelector from 'components/LocalizeSelector'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import { deleteAuthToken } from 'helpers/FetchHelper'
import { setGetNameSevice } from 'hooks/setGetNameService'
import useCheckWhiteList from 'hooks/useCheckWhiteList'
import useIsMobile from 'hooks/useIsMobile'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import { updateUserInfo } from 'state/user/actions'
import { useUserData } from 'state/user/hooks/useUserData'
import { useGetUsdtWallet } from 'state/wallet/useGetWalletData'
import styled from 'styled-components'
import { nodes, nodesCHZ } from 'utils/getRpcUrl'
import { NETWORK_CONFIG } from 'utils/wallet'
import { Link } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import CollapseIcon from './CollapseIcon'
import DropdownUserMenu from './DropdownUserMenu'
import MobileSlider from './MobileSlider'
import { MenuEntry } from './types'

const WrapperHeader = styled.header<{ background: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  transition: 0.25s;
  z-index: 15;
  background: ${({ background }) => background};

  .content {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    border-bottom: 0.5px solid #fff;
    background: rgba(0, 0, 0, 0.64);
    backdrop-filter: blur(4px);

    width: 100%;
    .menu {
      width: 1232px;
      display: flex;
      justify-content: space-between;
      margin: auto;
      padding: 16px 16px;

      .menu-left {
        margin-right: 5px;

        .menu-logo {
          display: flex;
          align-items: center;

          /* img {
            width: 54px;
            aspect-ratio: 1/1;
            border-radius: 100%;
          } */
        }
      }

      .menu-mid {
        font-weight: bold;
        gap: 12px;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 24px;

        ${({ theme }) => theme.mediaQueries.xl} {
          padding: 12px 44px;
          gap: 32px;
          font-size: 16px;
        }
      }

      .menu-right {
        display: flex;
        align-items: center;

        .notify-icon {
          width: 30px;
          position: relative;
          margin-right: 16px;
          cursor: pointer;

          span {
            position: absolute;
            top: 2px;
            right: -3px;
            width: 18px;
            height: 18px;
            background: #f41706;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 100%;
          }
        }

        #selected-country-btn {
          padding: 8px;
          border-color: #ffe15a;
          max-width: 80px;

          ${({ theme }) => theme.mediaQueries.md} {
            height: 55px;
            padding: 8px 16px;
            max-width: 200px;
            float: none;
          }

          > span > span:nth-child(2) {
            display: none;

            ${({ theme }) => theme.mediaQueries.sm} {
              display: inline-block;
            }
          }
        }

        .menu-actions-desktop {
          position: relative;
          display: flex;
          align-items: center;
          margin-left: 8px;

          a {
            width: 100%;

            &:not(:last-child) {
              margin-right: 13px;
            }
          }

          button {
            &:not(:last-child) {
              margin-right: 13px;
            }
          }
          .sign-in {
            background: #ffe15a;
            border-radius: 50px;
            border: 1.5px solid rgba(40, 188, 247, 0);
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 55px;
            > div {
              color: #000;
              align-items: center;
              display: flex;
              gap: 8px;
            }
          }
        }

        .mobile-menu-icon {
          width: 35px;
          height: 35px;
          margin: 10px;

          ${({ theme }) => theme.mediaQueries.xs} {
            margin: 15px;
          }
        }
      }
    }
  }

  .ant-select {
    position: relative;
    color: #000;
    margin-right: 5px;
    width: 80px;

    ${({ theme }) => theme.mediaQueries.sm} {
      width: 150px;
      margin-right: 12px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      color: #000;
      font-family: 'Clash Display';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 18px;
      }

      p {
        display: flex;
        align-items: center;

        img {
          display: none;

          ${({ theme }) => theme.mediaQueries.sm} {
            display: block;
            margin-right: 8px;
          }
        }

        span {
          display: block;
          margin-top: 3px;
        }
      }
    }

    .ant-select-selector {
      border-radius: 1000px;
      background: #e8e8e8;
      height: 35px;

      ${({ theme }) => theme.mediaQueries.sm} {
        height: 55px;
      }
    }

    .ant-select-arrow {
      width: 18px;
      background: url('/images/header/arrow_down.svg');
      background-position: center center;
      transform: translate(0%, -50%);
      background-size: cover;
      margin-top: 0;

      ${({ theme }) => theme.mediaQueries.sm} {
        width: 24px;
        transform: translate(-15%, -50%);
      }

      span {
        display: none;
      }
    }

    .ant-select-item-option-content {
      display: none;
    }
  }
`

const UserBox = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-left: 32px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        stroke: #00a0ff;
      }
    }
  }
`

interface Props {
  menuItems: MenuEntry[]
  hasSider?: boolean
}

const mobileWith = 991

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account, chainId } = useWeb3React()
  const userData = useUserData()
  const myWallet = useGetUsdtWallet()
  const [isWhiteList] = useCheckWhiteList(account)

  const [showSlider, setShowSlider] = useState(false)
  const [web3DomainName, setWeb3DomainName] = useState<string>('')

  useMemo(async () => {
    if (account && setGetNameSevice) {
      const nameSevice = await setGetNameSevice(account)

      setWeb3DomainName(nameSevice)
    }
  }, [account])

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  const handleSignOut = useCallback(() => {
    dispatch(updateUserInfo(undefined))
    const isDelete = deleteAuthToken()
    if (isDelete) {
      router.push('/sign-in')
    }
  }, [dispatch, router])

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const menuItemList = useMemo(() => {
    const result: any[] = []

    if (isWhiteList)
      result.push({
        key: '1',
        label: (
          <Link href={`/config/${account}`} className="hover:bg-[#34baff] p-2">
            Config Box
          </Link>
        ),
      })

    return result
  }, [account, isWhiteList])

  useMemo(async () => {
    if (Cookies.get('chainId')) {
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${(+Cookies.get('chainId')).toString(16)}` }],
        })
      }
    } else if (chainId) {
      if (chainId === 56 || chainId === 88888) {
        Cookies.set('chainId', `${chainId}`)
      }
    } else if (window.ethereum) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${(56).toString(16)}` }],
      })
      Cookies.set('chainId', `56`)
      window.location.reload()
    } else {
      Cookies.set('chainId', `88888`)
    }
  }, [chainId])

  const handleChangeChain = async (e) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${(+e).toString(16)}` }],
        })
      } catch (switchError) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(+e).toString(16)}`,
                chainName: e === 56 ? 'BNB Smart Chain Mainnet' : 'Chiliz Chain',
                nativeCurrency: {
                  name: e === 56 ? 'BNB' : 'CHZ',
                  symbol: e === 56 ? 'bnb' : 'chz',
                  decimals: 18,
                },
                rpcUrls: e === 56 ? nodes : nodesCHZ,
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          })
        } catch (error) {
          return
        }
      }
    }

    Cookies.set('chainId', `${e}`)
    window.location.reload()
  }

  return (
    <>
      <WrapperHeader background={!isScroll ? '#0A14141a' : '#0A14141a'}>
        <div className="content">
          <div className="menu flex">
            <div className="flex">
              {!hasSider ? (
                <>
                  <div className="menu-left flex">
                    <div className="menu-logo">
                      <Link href="/" className="flex items-center gap-3">
                        <img src="/images/new-logo.png" alt="" className="w-full max-w-[54px] aspect-square" />
                        <span className="text-[24px] uppercase font-bold text-white">Bunny</span>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <div />
              )}
              {!isMobile && (
                <div className="menu-mid">
                  {menuItems?.map((item) => (
                    <Link href={item?.href} external={item?.href?.slice(4) === 'http'}>
                      {t(item?.label)}
                    </Link>
                  ))}
                  {account && isWhiteList && (
                    <Dropdown
                      className="cursor-pointer"
                      menu={{
                        items: menuItemList,
                      }}
                      placement="topLeft"
                    >
                      <p>Config</p>
                    </Dropdown>
                  )}
                </div>
              )}
            </div>

            <div className="menu-right">
              {/* <Select
                defaultValue="56"
                value={Cookies.get('chainId')}
                onChange={handleChangeChain}
                options={[
                  {
                    value: '56',
                    label: (
                      <p>
                        <img src="/images/header/BSC-chain.png" alt="" /> <span>BSC</span>
                      </p>
                    ),
                  },
                  {
                    value: '88888',
                    label: (
                      <p>
                        <img src="/images/header/CHZ-chain.png" alt="" /> <span>CHZ</span>
                      </p>
                    ),
                  },
                ]}
                popupClassName="select-menu"
              /> */}

              <LocalizeSelector />

              {isMobile ? (
                <CollapseIcon active={showSlider} toggleMenu={toggleMenu} />
              ) : (
                <div className="menu-actions-desktop">
                  {!userData ? (
                    <>
                      {/* <LocalizeSelector /> */}
                      <BaseButton className="auth-btn sign-in" onClick={toggleWallet}>
                        <img src="/images/header/wallet.png" alt="img" />
                        {web3DomainName || formatCode(account, 5, 5) || t('Connect Wallet')}
                      </BaseButton>
                    </>
                  ) : (
                    <UserBox>
                      <Dropdown
                        overlay={
                          <DropdownUserMenu
                            userData={userData}
                            toggleMenu={toggleMenu}
                            onSignOut={handleSignOut}
                            myWallet={myWallet}
                          />
                        }
                        placement="bottomLeft"
                        trigger={['click']}
                        open={showSlider}
                        onOpenChange={toggleMenu}
                      >
                        <Box as="img" src={userData?.avatar} alt="" />
                      </Dropdown>
                    </UserBox>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </WrapperHeader>
      {isMobile && (
        <MobileSlider
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
          userData={userData}
          onSignOut={handleSignOut}
          myWallet={myWallet}
          web3DomainName={web3DomainName}
          isWhiteList={isWhiteList}
          isSharingAdminRole={false}
        />
      )}
    </>
  )
}

export default Header
