/* eslint-disable import/extensions */
import axios, { AxiosResponse } from 'axios'
import { ENDPOINT_AFFILIATE_API } from 'config/constants/endpoints'
import { UserAffiliateResponse } from './types'

export const getUserAffiliate = (address: string) => {
  return axios.get<any, AxiosResponse<UserAffiliateResponse>>(`${ENDPOINT_AFFILIATE_API}/referral-affiliate/${address}`)
}

export const setUserAffiliate = (address: string, refAddress: string) => {
  return axios.post(`${ENDPOINT_AFFILIATE_API}/referral-affiliate`, { address, refAddress })
}
